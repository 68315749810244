<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto">
                    <v-container>
                        <v-tabs v-model="tab" center-active>
                            <v-tab>{{ $t('Category') }}</v-tab>
                            <v-tab>{{ $t('Entities') }}</v-tab>
                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <v-row class="mt-10">
                                        <v-col
                                            class="form-pic justify-center d-flex d-xs-flex d-sm-flex d-md-none"
                                            cols="12"
                                            md="3">
                                            <v-avatar
                                                :color="form.icon ? '' : 'primary'"
                                                :class="form.icon ? '' : 'v-avatar-light-bg primary--text'"
                                                size="200">
                                                <v-img v-if="getIconPic" :src="getIconPic"></v-img>
                                                <span v-else class="display-1 font-weight-medium">{{
                                                    avatarText(form.name)
                                                }}</span>
                                                <v-file-input
                                                    name="photo"
                                                    v-show="!isDisabled"
                                                    @change="onIconFileInput"
                                                    v-model="iconFile"
                                                    class="file"
                                                    hide-input></v-file-input>
                                            </v-avatar>
                                        </v-col>
                                        <v-col
                                            class="form-pic justify-center d-flex d-xs-flex d-sm-flex d-md-none"
                                            cols="12"
                                            md="3">
                                            <v-avatar
                                                :color="form.marker ? '' : 'primary'"
                                                :class="form.marker ? '' : 'v-avatar-light-bg primary--text'"
                                                size="200">
                                                <v-img v-if="getMarkerPic" :src="getMarkerPic"></v-img>
                                                <span v-else class="display-1 font-weight-medium">{{
                                                    avatarText(form.name)
                                                }}</span>
                                                <v-file-input
                                                    name="photo"
                                                    v-show="!isDisabled"
                                                    @change="onMarkerFileInput"
                                                    v-model="markerFile"
                                                    class="file"
                                                    hide-input></v-file-input>
                                            </v-avatar>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <ValidationProvider
                                                        :name="$t('Form.Name')"
                                                        rules="required"
                                                        v-slot="{ errors }">
                                                        <v-text-field
                                                            :error="errors[0] ? true : false"
                                                            v-model="form.name"
                                                            :disabled="isDisabled"
                                                            :label="$t('Form.Name')"
                                                            required
                                                            filled
                                                            hide-details="auto"
                                                            name="name"
                                                            shaped></v-text-field>
                                                        <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <!-- icon -->
                                        <v-col cols="12">
                                            <v-label>Ícone:</v-label>
                                        </v-col>
                                        <v-col class="form-pic" cols="12" md="3">
                                            <v-avatar
                                                :color="form.icon ? '' : 'primary'"
                                                :class="form.icon ? '' : 'v-avatar-light-bg primary--text'"
                                                size="150">
                                                <v-img v-if="getIconPic" :src="getIconPic"></v-img>
                                                <span v-else class="display-1 font-weight-medium">{{
                                                    avatarText(form.name)
                                                }}</span>
                                                <v-file-input
                                                    name="photo"
                                                    v-show="!isDisabled"
                                                    @change="onIconFileInput"
                                                    v-model="iconFile"
                                                    class="file"
                                                    hide-input></v-file-input>
                                            </v-avatar>
                                        </v-col>
                                        <!-- marker -->
                                        <v-col cols="12">
                                            <v-label>Marcador:</v-label>
                                        </v-col>
                                        <v-col class="form-pic" cols="12" md="3">
                                            <v-avatar
                                                :color="form.marker ? '' : 'primary'"
                                                :class="form.marker ? '' : 'v-avatar-light-bg primary--text'"
                                                size="150">
                                                <v-img v-if="getMarkerPic" :src="getMarkerPic"></v-img>
                                                <span v-else class="display-1 font-weight-medium">{{
                                                    avatarText(form.name)
                                                }}</span>
                                                <v-file-input
                                                    name="photo"
                                                    v-show="!isDisabled"
                                                    @change="onMarkerFileInput"
                                                    v-model="markerFile"
                                                    class="file"
                                                    hide-input></v-file-input>
                                            </v-avatar>
                                        </v-col>
                                    </v-row>
                                    <v-row> </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-data-table
                                        :show-select="!isDisabled"
                                        calculate-widths
                                        v-model="selectedEntities"
                                        :headers="headers"
                                        :items="
                                            selectedEntities.length > 0 && (searchEntity == null || searchEntity == '')
                                                ? orderBySelectedEntities
                                                : entities
                                        "
                                        :loading="isLoadingTable"
                                        :server-items-length="totalEntities"
                                        :items-per-page="pageSize"
                                        :options.sync="options"
                                        item-key="id"
                                        :footer-props="{
                                            'items-per-page-options': [6, 12, 18],
                                            'items-per-page-text': $t('RowsPerPage'),
                                        }"
                                        class="mt-15"
                                        :header-props="{ 'sort-by-text': $t('SortBy') }">
                                        <template v-slot:top>
                                            <v-text-field
                                                v-model="search"
                                                :placeholder="$t('Search')"
                                                outlined
                                                hide-details
                                                dense
                                                expanded
                                                :prepend-inner-icon="mdiMagnify"
                                                class="mb-6"
                                                @keydown.enter.prevent="getEntitiesName" />
                                        </template>
                                        <!-- entity -->
                                        <template #[`item.name`]="{ item }">
                                            <v-avatar
                                                :color="item.avatar ? '' : 'primary'"
                                                :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                                                size="32">
                                                <v-img
                                                    v-if="resolveEntityPicture(item.icon)"
                                                    :src="resolveEntityPicture(item.icon)"></v-img>
                                                <span v-else class="caption font-weight-medium">{{
                                                    avatarText(item.name)
                                                }}</span>
                                            </v-avatar>
                                            <span class="ftext--primary font-weight-semibold text-truncate ms-3">{{
                                                item.name
                                            }}</span>
                                        </template>
                                    </v-data-table>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-tabs>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="$router.go(-1)" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="!addEntityCategory">
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('EntityCategoryProfile') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('EntityCategoryProfile') }}
                        </p>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('AddEntityCategory') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('AddEntityCategory') }}
                        </p>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <div v-if="!addEntityCategory">
                        <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                        </v-btn>
                        <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                        </v-btn>
                        <v-btn v-show="isDisabled" @click="editInformation" class="mx-3" color="grey-light">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                        </v-btn>

                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                <v-card>
                                    <v-card-text class="text-center">
                                        <p class="title font-weight-semibold black--text mt-12">
                                            {{ $t('Alerts.Sure') }}
                                        </p>
                                    </v-card-text>
                                    <v-card-text class="text-center">
                                        <p class="body-2">
                                            {{ $t('Alerts.SureDeleteEntityCategory') }}
                                            <strong class="black--text">{{ form.name }}</strong> ?<br />
                                            {{ $t('Alerts.Irreversible') }}
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end mt-4">
                                        <v-btn @click="deleteEntityCategory" color="error" class="px-5">{{
                                            $t('Buttons.Delete')
                                        }}</v-btn>
                                        <v-btn text @click="dialog.value = false">{{ $t('Buttons.Cancel') }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <!-- ALERTAS DE SUCESSO E ERRO A ELIMINAR AS CATEGORIAS -->
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.AddEntityCategorySuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="updateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.UpdateEntityCategorySuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isDeleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.DeleteEntityCategorySuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>
<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
    } from '@mdi/js';
    import useEntityCategoryList from '../entity-category-list/useEntityCategoryList';
    import { avatarText } from '@core/utils/filter';
    import { uploadFile } from '@/api/upload';
    import _ from 'lodash';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import useEntityList from '../../../views/entities/entity-list/useEntityList';
    import { getEntities } from '@/api/entity.js';

    const initialState = () => ({
        isLoading: false,
        form: {
            name: '',
            icon: null,
            marker: null,
        },
        tab: 0,
        isLoadingTable: true,
        structure: [],
        headers: [
            {
                text: '',
                sortable: true,
                value: 'name',
            },
            {
                text: '',
                sortable: true,
                value: 'email',
            },
            {
                text: '',
                sortable: true,
                value: 'location.name',
            },
            {
                text: '',
                sortable: true,
                value: 'location.parent.name',
            },
            {
                text: '',
                sortable: true,
                value: 'location.parent.parent.name',
            },
        ],
        search: null,
    });

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,
                nameRules: [
                    (v) => !!v || 'Name is required',
                    // (v) => v.length <= 10 || 'Name must be less than 10 characters',
                ],
                //   costRules: [(v) => !!v || 'Cost is required', (v) => (v && v <= 0) || 'Não pode ser menor que 0'],

                iconFile: null,
                markerFile: null,
                addEntityCategory: false,
                dialog: false,
                createSuccess: false,
                updateSuccess: false,
                isDeleteSuccess: false,
                isError: false,
                coordinatesField: null,
                selectedEntityCategoryType: null,
                selectedEntities: [],
                searchEntity: null,
                entities: [],
                totalEntities: null,
                page: 1,
                pageSize: 12,
                options: {
                    page: 1,
                    itemsPerPage: 12,
                },
            };
        },
        async created() {
            this.headers[0].text = this.$t('Name');
            this.headers[1].text = this.$t('Email');
            this.headers[2].text = this.$t('District');
            this.headers[3].text = this.$t('County');
            this.headers[4].text = this.$t('Parish');
            if (this.$route.params.id != 'add') {
                this.addEntityCategory = false;
                this.isDisabled = true;
                await this.getEntityCategory();
            } else {
                this.addEntityCategory = true;
                this.isDisabled = false;
            }
            this.getEntitiesName();
        },
        watch: {
            options: {
                handler() {
                    this.page = this.options.page;
                    this.pageSize = this.options.itemsPerPage;
                    this.getEntitiesName();
                },
            },
        },
        setup() {
            const { resolveEntityPicture } = useEntityList();
            const { resolveEntityCategoryPictureMax, limitEntityText } = useEntityCategoryList();

            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,
                resolveEntityCategoryPictureMax,
                avatarText,
                limitEntityText,

                resolveEntityPicture,
            };
        },
        computed: {
            getIconPic() {
                return this.resolveEntityCategoryPictureMax(this.form.icon);
            },
            getMarkerPic() {
                return this.resolveEntityCategoryPictureMax(this.form.marker);
            },
            orderBySelectedEntities() {
                for (let index = 0; index < this.selectedEntities.length; index++) {
                    this.entities.unshift(this.selectedEntities[index]);

                    //remove duplicates from array
                    this.entities = this.entities.filter(
                        (entity, index, self) => index === self.findIndex((i) => i.id === entity.id),
                    );
                }
                return this.entities;
            },
        },
        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },

            //get entity info
            getEntityCategory() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-entity-category/fetchEntityCategory', this.$route.params.id)
                    .then((response) => {
                        this.form = response.data.data;
                        let entities = _.get(this.form, 'accessibleLocations');
                        this.selectedEntities = entities;
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            async onSubmit() {
                if (this.addEntityCategory) {
                    await this.onAddEntityCategory();
                } else await this.onUpdateEntityCategory();

                setTimeout(() => {
                    this.$router.push({ name: 'EntityCategoryList' });
                }, 1500);
            },

            //add entity
            async onAddEntityCategory() {
                this.isLoading = true;

                let selectedEntitiesId = this.selectedEntities.map((entity) => {
                    return entity.id;
                });

                let entityCategoryData = _.assign({}, this.form); // Vai pegar todos os dados do formulário e jogar no objeto entityCategoryData (colonar objeto) é o que faz a função assing do lodash
                entityCategoryData.accessibleLocations = selectedEntitiesId;

                await this.$store
                    .dispatch('app-entity-category/addEntityCategory', entityCategoryData)
                    .then(async (response) => {
                        this.createSuccess = true;
                    })

                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            //update entity
            async onUpdateEntityCategory() {
                this.isLoading = true;

                let selectedEntitiesId = this.selectedEntities.map((entity) => {
                    return entity.id;
                });

                let entityCategoryData = _.assign({}, this.form); // Vai pegar todos os dados do formulário e jogar no objeto entityCategoryData (colonar objeto) é o que faz a função assing do lodash
                entityCategoryData.accessibleLocations = selectedEntitiesId;
                entityCategoryData.location = this.selectedParish;

                //update entity
                await this.$store
                    .dispatch('app-entity-category/updateEntityCategory', entityCategoryData)

                    .then(async (response) => {
                        this.updateSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            async onIconFileInput() {
                this.isLoading = true;
                await uploadFile(this.iconFile).then((response) => {
                    this.form.icon = response.data[0];
                });
                this.isLoading = false;
            },
            async onMarkerFileInput() {
                this.isLoading = true;
                await uploadFile(this.markerFile).then((response) => {
                    this.form.marker = response.data[0];
                });
                this.isLoading = false;
            },
            async getEntitiesName() {
                getEntities({
                    populate: 'location.parent.parent, icon',
                    sort: ['name:asc'],
                    'filters[$or]': [
                        {
                            name: {
                                $containsi: this.search,
                            },
                        },
                        {
                            location: {
                                name: {
                                    $containsi: this.search,
                                },
                            },
                        },
                        {
                            location: {
                                parent: {
                                    name: {
                                        $containsi: this.search,
                                    },
                                },
                            },
                        },
                        {
                            location: {
                                parent: {
                                    parent: {
                                        name: {
                                            $containsi: this.search,
                                        },
                                    },
                                },
                            },
                        },
                    ],
                    pagination: {
                        page: this.page,
                        pageSize: this.pageSize,
                    },
                })
                    .then((response) => {
                        this.entities = response.data.data;
                        this.totalEntities = response.data.meta.pagination.total;
                        this.pagination = response.data.meta.pagination;
                    })
                    .finally(() => {
                        this.isLoadingTable = false;
                    });
            },
            deleteEntityCategory() {
                this.$store
                    .dispatch('app-entity-category/deleteEntityCategory', this.$route.params.id)
                    .then((response) => {
                        this.isDeleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'EntityCategoryList' });
                        }, 1500);
                    })
                    .catch((error) => {
                        this.isError = true;
                    });
            },
        },
    };
</script>
<style lang="scss" scoped>
    .is-icon {
        background-color: #0000ff;
        border-radius: 10px;
    }

    .v-avatar {
        position: relative;
        .file {
            background-color: #0000ff;
            border-radius: 10px;
            padding: 0.3rem;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(0%, 0%);
        }
    }
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
